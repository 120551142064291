import React, { useContext, useEffect } from "react";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { useStateWithLocalStorage } from "../../../hooks/useStateWithLocalStorage/useStateWithLocalStorage";
import { SuccessStoriesCarousel } from "../../new-member/components/SuccessStoriesCarousel";
import { OnboardingGenderPage } from "../../onboarding/pages";
import { PersonalizedPlanSalesPage, WelcomePage } from "../../../pages";
import { useNavigate } from "../../../components/Link/Link";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import PreviewMealPlanCard from "../../../components/MealPlanCard/PreviewMealPlanCard/PreviewMealPlanCard";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { GenerateMealPlan_generateMealplan } from "../../../types/generateMealplan";
import { StaticImage } from "gatsby-plugin-image";
import TrustPilotMicro from "../../../components/TrustPilotMicro/TrustPilotMicro";
import { ThreeGoalsList } from "../components/ThreeGoalsList";
import { PersonalizedPlanNavbar } from "../components/PersonalizedPlanNavbar";

const PersonalizedPlanProjectionPage = () => {
  const { locale } = useContext(pageContext);

  const { loading: isUserLoading } = useContext(LoginContext);

  const navigate = useNavigate();

  const [anonMealPlan] = useStateWithLocalStorage<string | undefined>(
    "dd/onboarding/anonymous-meal-plan",
    undefined
  );

  useEffect(() => {
    if (!isUserLoading) {
      if (!anonMealPlan) {
        navigate({
          to: OnboardingGenderPage,
        });
      }
    }
  }, [anonMealPlan, isUserLoading, navigate]);

  return (
    <>
      <SEOReactHelmet noindex title="Diet Doctor - Meal Plan Preview" lang={locale as string} />

      {/* Navbar */}
      <PersonalizedPlanNavbar />

      {/* Success state */}
      {typeof anonMealPlan !== "undefined" && (
        <main>
          {/* Meal plan preview */}
          <section className="flex flex-col items-center text-center max-w-4xl mx-auto px-4">
            <h2 className="text-black text-4xl mt-6 sm:mt-12 max-w-xs">
              Your personalized meal plan is ready!
            </h2>

            <p className="text-lg mt-4 mb-6 -mx-0.5 leading-tight max-w-md">
              We’ve personalized it to fit your goals. You can change recipes to suit your needs.
            </p>

            <PreviewMealPlanCard
              mealPlan={JSON.parse(anonMealPlan) as GenerateMealPlan_generateMealplan}
              errorMessage={"Oops! We can't accommodate all your preferences yet."}
            />
          </section>

          {/* Goals */}
          <section className="flex flex-col items-center text-center max-w-7xl mx-auto px-4 mt-8">
            <h3 className="text-xl font-medium mb-4">Highlights of your personalized plan:</h3>

            <ThreeGoalsList />
          </section>

          {/* Arrow down */}
          <a className="inline-block w-full mt-10" href="#graph">
            <svg
              className="block w-8 h-8 mx-auto"
              viewBox="0 0 30 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.402 17.144a2 2 0 002.828-.011L28.91 4.357a2 2 0 10-2.84-2.818L14.8 12.896 3.443 1.626A2 2 0 00.626 4.463l12.776 12.68zm-.598-3.136l.007 1.724 4-.015-.007-1.725-4 .016z"
                fill="#404040"
              />
            </svg>
          </a>

          {/* Graph */}
          <section className="flex flex-col items-center text-center max-w-7xl mx-auto px-4 mt-10 relative">
            <div id="graph" className="absolute invisible -mt-6"></div>
            <h3 className="text-3xl font-medium">
              Based on your answers,
              <br />
              we can help you reach your goals
            </h3>

            <StaticImage
              src="../../../../static/images/onboarding-AB-test/get-here-graph.jpg"
              placeholder="none"
              height={320}
              objectFit="contain"
              alt="graph"
              className="max-w-lg mt-6"
              loading="eager"
            />
            <br />
            <ProjectionPageButton />
          </section>

          {/* Features */}
          <section className="bg-light-beige text-center py-12 mt-12">
            <h3 className="text-4xl">How you’ll get there</h3>
            <h4 className="text-xl font-normal mt-2">
              Try it all <b>FREE</b> for 30 days.
            </h4>

            <div className="flex flex-col items-center">
              <div className="bg-white flex flex-row items-center max-w-md rounded-lg mt-6 mx-2">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/1-feature.jpg"
                  placeholder="none"
                  height={224}
                  objectFit="contain"
                  alt="feature one"
                  className="rounded-full m-4 w-16 sm:w-20 shrink-0"
                />
                <p className="text-base sm:text-lg m-4 ml-2 text-left">
                  Get support and stay on track in our community. Ask questions, get insights, and be inspired.
                </p>
              </div>

              <div className="bg-white flex flex-row items-center max-w-md rounded-lg mt-6 mx-2">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/2-feature.jpg"
                  placeholder="none"
                  height={224}
                  objectFit="contain"
                  alt="feature two"
                  className="rounded-full m-4 w-16 sm:w-20 shrink-0"
                />
                <p className="text-base sm:text-lg m-4 ml-2 text-left">
                  Learn from doctors, dietitians, and experts to support and empower you on your journey.
                </p>
              </div>

              <div className="bg-white flex flex-row items-center max-w-md rounded-lg mt-6 mx-2">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/3-feature.jpg"
                  placeholder="none"
                  height={224}
                  objectFit="contain"
                  alt="feature three"
                  className="m-4 w-16 sm:w-20 shrink-0"
                />
                <p className="text-base sm:text-lg m-4 ml-2 text-left">
                  We’ll personalize an experience you’ll love, tailored from our catalog of 1,200+ recipes.
                </p>
              </div>

              <div className="bg-white flex flex-row items-center max-w-md rounded-lg mt-6 mx-2">
                <StaticImage
                  src="../../../../static/images/onboarding-AB-test/4-feature.jpg"
                  placeholder="none"
                  height={224}
                  objectFit="contain"
                  alt="feature three"
                  className="m-4 w-16 sm:w-20 shrink-0"
                />
                <p className="text-base sm:text-lg m-4 ml-2 text-left">
                  Weight loss minus the counting and guessing. Take it one day at a time with our app.
                </p>
              </div>

              <ProjectionPageButton />
            </div>
          </section>

          {/* Success stories */}
          <section>
            <div className="pt-12 px-4 md:px-12 max-w-fit mx-auto">
              <h2 className="text-3xl text-center">
                We’ve helped over
                <br />2 million people
                <br />
                improve their health
              </h2>
              <div className="mx-auto mt-6 max-w-[600px]">
                <SuccessStoriesCarousel />
              </div>
            </div>
          </section>
          <section className="text-center mt-12 pb-24 max-w-7xl mx-auto px-4">
            <ProjectionPageButton className="bg-blue-500" />
          </section>
        </main>
      )}
    </>
  );
};

// ts-prune-ignore-next
export default PersonalizedPlanProjectionPage;

// Misc components
function ProjectionPageButton({ className }: { className?: string }) {
  const navigate = useNavigate();

  const { user, premium } = useContext(LoginContext);

  const buttonClassNames = `block shadow-md hover:shadow-none bg-green px-8 py-4 text-lg mx-auto rounded-lg text-white text-xl hover:underline focus:opacity-95 transition-all font-medium min-w-[335px] mt-12 ${
    className ? className : ""
  }`;

  return (
    <>
      {/* The user has no account -> to the create sign up page */}
      {(!user || (user && !premium)) && (
        <button
          className={buttonClassNames}
          onClick={() =>
            navigate({
              to: PersonalizedPlanSalesPage,
              query: {
                content: "onboarding",
              },
            })
          }
        >
          {"Start 30-day free trial"}
        </button>
      )}

      {/* The user has a premium subscription -> to the home page */}
      {user && premium && (
        <button
          className={buttonClassNames}
          onClick={() =>
            navigate({
              to: WelcomePage,
              query: {
                content: "onboarding",
              },
            })
          }
        >
          {"Go to my homepage"}
        </button>
      )}
    </>
  );
}
